// Generated by Framer (d2b7042)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["RdJUJl7V7", "AgLeDGNnm", "oiX6hcFlF", "FWCTkFTdp", "umWsnR4gh", "JzkKfKvoS", "xOk_xEWvf", "t1LVJbNrt", "OwlNFGfGY"];

const serializationHash = "framer-Z2pqR"

const variantClassNames = {AgLeDGNnm: "framer-v-1h3ol6y", FWCTkFTdp: "framer-v-166rvdc", JzkKfKvoS: "framer-v-jz6xdc", oiX6hcFlF: "framer-v-se7ms", OwlNFGfGY: "framer-v-by62mf", RdJUJl7V7: "framer-v-jxzh0u", t1LVJbNrt: "framer-v-v8rcj1", umWsnR4gh: "framer-v-184t5x7", xOk_xEWvf: "framer-v-1pv6bav"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}, FWCTkFTdp: {delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], type: "tween"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Image 1:1": "JzkKfKvoS", "Image 16:9": "FWCTkFTdp", "Image 3:2": "oiX6hcFlF", "Image 4:3": "AgLeDGNnm", "Image 4:5": "umWsnR4gh", "Variant 7": "xOk_xEWvf", "Variant 8": "t1LVJbNrt", "Variant 9": "OwlNFGfGY", Default: "RdJUJl7V7"}

const getProps = ({height, id, image, mouseEnter, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "RdJUJl7V7", vhrLqezAm: mouseEnter ?? props.vhrLqezAm, WKR34BYYJ: image ?? props.WKR34BYYJ} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;image?: {src: string; srcSet?: string};mouseEnter?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, WKR34BYYJ, vhrLqezAm, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "RdJUJl7V7", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnterslyzpm = activeVariantCallback(async (...args) => {
if (vhrLqezAm) {
const res = await vhrLqezAm(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition}><Image {...restProps} background={{alt: "", fit: "fill", sizes: "min(256px, 100vw)", ...toResponsiveImage(WKR34BYYJ)}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-jxzh0u", className, classNames)} data-framer-name={"Default"} data-highlight layoutDependency={layoutDependency} layoutId={"RdJUJl7V7"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onMouseEnter={onMouseEnterslyzpm} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({AgLeDGNnm: {"data-framer-name": "Image 4:3"}, FWCTkFTdp: {"data-framer-name": "Image 16:9"}, JzkKfKvoS: {"data-framer-name": "Image 1:1"}, oiX6hcFlF: {"data-framer-name": "Image 3:2"}, OwlNFGfGY: {"data-framer-name": "Variant 9"}, t1LVJbNrt: {"data-framer-name": "Variant 8"}, umWsnR4gh: {"data-framer-name": "Image 4:5"}, xOk_xEWvf: {"data-framer-name": "Variant 7"}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = [".framer-Z2pqR[data-border=\"true\"]::after, .framer-Z2pqR [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Z2pqR.framer-jp5zx, .framer-Z2pqR .framer-jp5zx { display: block; }", ".framer-Z2pqR.framer-jxzh0u { height: 256px; overflow: hidden; position: relative; width: 256px; }", ".framer-Z2pqR.framer-v-1h3ol6y.framer-jxzh0u { aspect-ratio: 1.3333333333333333 / 1; height: var(--framer-aspect-ratio-supported, 192px); }", ".framer-Z2pqR.framer-v-se7ms.framer-jxzh0u { aspect-ratio: 1.4970760233918128 / 1; height: var(--framer-aspect-ratio-supported, 171px); }", ".framer-Z2pqR.framer-v-166rvdc.framer-jxzh0u { aspect-ratio: 1.7777777777777777 / 1; height: var(--framer-aspect-ratio-supported, 144px); }", ".framer-Z2pqR.framer-v-184t5x7.framer-jxzh0u { aspect-ratio: 0.8 / 1; height: var(--framer-aspect-ratio-supported, 320px); }", ".framer-Z2pqR.framer-v-jz6xdc.framer-jxzh0u { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 256px); }", ".framer-Z2pqR.framer-v-1pv6bav.framer-jxzh0u { aspect-ratio: 2.56 / 1; height: var(--framer-aspect-ratio-supported, 100px); }", ".framer-Z2pqR.framer-v-v8rcj1.framer-jxzh0u, .framer-Z2pqR.framer-v-by62mf.framer-jxzh0u { aspect-ratio: 0.5688888888888889 / 1; height: var(--framer-aspect-ratio-supported, 450px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 256
 * @framerIntrinsicWidth 256
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"AgLeDGNnm":{"layout":["fixed","fixed"]},"oiX6hcFlF":{"layout":["fixed","fixed"]},"FWCTkFTdp":{"layout":["fixed","fixed"]},"umWsnR4gh":{"layout":["fixed","fixed"]},"JzkKfKvoS":{"layout":["fixed","fixed"]},"xOk_xEWvf":{"layout":["fixed","fixed"]},"t1LVJbNrt":{"layout":["fixed","fixed"]},"OwlNFGfGY":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"WKR34BYYJ":"image","vhrLqezAm":"mouseEnter"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerdxfeapgsR: React.ComponentType<Props> = withCSS(Component, css, "framer-Z2pqR") as typeof Component;
export default FramerdxfeapgsR;

FramerdxfeapgsR.displayName = "Image / Aspect Ratio Copy";

FramerdxfeapgsR.defaultProps = {height: 256, width: 256};

addPropertyControls(FramerdxfeapgsR, {variant: {options: ["RdJUJl7V7", "AgLeDGNnm", "oiX6hcFlF", "FWCTkFTdp", "umWsnR4gh", "JzkKfKvoS", "xOk_xEWvf", "t1LVJbNrt", "OwlNFGfGY"], optionTitles: ["Default", "Image 4:3", "Image 3:2", "Image 16:9", "Image 4:5", "Image 1:1", "Variant 7", "Variant 8", "Variant 9"], title: "Variant", type: ControlType.Enum}, WKR34BYYJ: {title: "Image", type: ControlType.ResponsiveImage}, vhrLqezAm: {title: "Mouse Enter", type: ControlType.EventHandler}} as any)

addFonts(FramerdxfeapgsR, [])